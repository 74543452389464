/* eslint camelcase: off */
import { defineStore } from 'pinia';
import { convertToMap } from '@@/utils/CommonUtils';
import { useUserStore } from '@@/stores/User';

const findAlert = (alerts, alertId, self) => {
  const alert = alerts?.find((a) => a.alert_id === alertId);

  if (alert) {
    return {
      ...alert,
      level: self.getLevelForAlert(alert),
    };
  }

  return null;
};

const getForecastAlertBySlug = (myState, slug) => myState
  ?.forecast_alerts
  ?.find((fa) => fa.slug === slug);

export const useMetaStore = defineStore('meta', {
  state: () => ({
    countries: null,
    data_sources: null,
    forecast_alerts: null,
    location_types: null,
    pricing: null,
    regions: null,
  }),

  actions: {
    async fetchLocationTypes() {
      const { $api } = useNuxtApp();
      const { location_types, location_types_maps } = await $api.get('/meta/location-types');
      this.setProperty({ location_types });
      this.setProperty({ location_types_maps });
    },

    async fetchPricing() {
      if (!this.pricing) {
        const { $api } = useNuxtApp();
        const pricing = await $api.get('/meta/pricing', { all: true });
        this.setProperty({ pricing });
      }
    },

    async fetchSeasonPasses() {
      const { $api } = useNuxtApp();
      const { season_passes } = await $api.get('/meta/season-passes');
      return season_passes;
    },

    async fetchSeed(payload = {}) {
      const userStore = useUserStore();

      const {
        forceUpdate = false,
        units = userStore?.preferences?.units || 'imperial',
      } = payload;

      const needsUpdate = forceUpdate
        || !this.countries
        || !this.data_sources
        || !this.favorite_list_types
        || !this.forecast_alerts
        || !this.location_types
        || !this.location_types_maps
        || !this.regions
        || !this.pricing;

      if (!needsUpdate) {
        return;
      }

      const { $api } = useNuxtApp();
      const response = await $api.get('/meta/seed', { units });

      const {
        countries,
        data_sources,
        favorite_list_types,
        forecast_alerts,
        location_types,
        location_types_maps,
        regions,
        pricing,
      } = response;

      this.setProperty({ countries });
      this.setProperty({ data_sources });
      this.setProperty({ favorite_list_types });
      this.setProperty({ forecast_alerts });
      this.setProperty({ location_types });
      this.setProperty({ location_types_maps });
      this.setProperty({ regions });
      this.setProperty({ pricing });
    },

    setProperty(property) {
      const [key, value] = Object.entries(property)[0];
      this[key] = value;
    },
  },

  getters: {
    dataSourcesById(myState) {
      return !myState.data_sources ? {} : convertToMap(myState.data_sources);
    },

    getAttributionBySourceIdAndField() {
      const self = this;

      return (sourceId, field) => {
        const dataSource = self.dataSourcesById[sourceId];

        if (!dataSource) {
          return null;
        }

        return dataSource.attribution.find((a) => a.applies_to.includes(field));
      };
    },

    getFeelsLikeAlert() {
      const self = this;
      return (alerts = []) => findAlert(alerts, self.feelsLikeAlert.id, self);
    },

    getForecastAlertById: (myState) => (id) => myState
      ?.forecast_alerts
      ?.find((fa) => fa.id === id),

    getLevelForAlert() {
      const self = this;

      return (alert) => {
        const forecastAlert = self.getForecastAlertById(alert?.alert_id);
        return forecastAlert?.levels.find((level) => level.id === alert.level_id);
      };
    },

    getLocationTypeBySlug() {
      const self = this;
      return (slug) => self.location_types?.find((locationType) => locationType.slug === slug);
    },

    getLightningAlert() {
      const self = this;
      return (alerts = []) => findAlert(alerts, self.lightningForecastAlert.id, self);
    },

    getPowderAlert() {
      const self = this;
      return (alerts = []) => findAlert(alerts, self.powderForecastAlert.id, self);
    },

    getPricingPlans(myState) {
      return myState.pricing?.plans?.reduce((acc, plan) => {
        if (plan.is_active) {
          acc[plan.slug] = plan;
        }

        return acc;
      }, {});
    },

    getSnowLevelAlert() {
      const self = this;
      return (alerts = []) => findAlert(alerts, self.snowLevelForecastAlert.id, self);
    },

    getSnowObservationAlert() {
      const self = this;
      return (alerts = []) => findAlert(alerts, self.snowObservationAlert.id, self);
    },

    getSuspectObservationAlert() {
      const self = this;
      return (alerts = []) => findAlert(alerts, self.suspectObservationAlert.id, self);
    },

    getSuspectReportAlert() {
      const self = this;
      return (alerts = []) => findAlert(alerts, self.suspectReportAlert.id, self);
    },

    getTemperatureForecastAlert() {
      const self = this;
      return (alerts = []) => findAlert(alerts, self.temperatureForecastAlert.id, self);
    },

    getTrailConditionsAlert() {
      const self = this;
      return (alerts = []) => findAlert(alerts, self.trailConditionsForecastAlert.id, self);
    },

    getWindGustAlert() {
      const self = this;
      return (alerts = []) => findAlert(alerts, self.windGustForecastAlert.id, self);
    },

    getWindAlert() {
      const self = this;
      return (alerts = []) => findAlert(alerts, self.windForecastAlert.id, self);
    },

    cloudCoverForecastAlert(myState) {
      return getForecastAlertBySlug(myState, 'cloud-cover');
    },

    cloudCoverForecastAlertIcon() {
      return this.cloudCoverForecastAlert?.icon_url;
    },

    feelsLikeAlert(myState) {
      return getForecastAlertBySlug(myState, 'feels-like');
    },

    lightningForecastAlert(myState) {
      return getForecastAlertBySlug(myState, 'lightning');
    },

    lightningForecastAlertIcon() {
      return this.lightningForecastAlert?.icon_url;
    },

    locationTypesById(myState) {
      return !myState.location_types ? {} : convertToMap(myState.location_types);
    },

    powderForecastAlert(myState) {
      return getForecastAlertBySlug(myState, 'powder');
    },

    precipForecastAlert(myState) {
      return getForecastAlertBySlug(myState, 'precip');
    },

    precipForecastAlertIcon() {
      return this.precipForecastAlert?.icon_url;
    },

    skiAreaLocationTypes(myState) {
      return myState.location_types?.filter(({ slug }) => slug.includes('-ski'));
    },

    snowLevelForecastAlert(myState) {
      return getForecastAlertBySlug(myState, 'snow-level');
    },

    snowObservationAlert(myState) {
      return getForecastAlertBySlug(myState, 'snow-observation');
    },

    suspectObservationAlert(myState) {
      return getForecastAlertBySlug(myState, 'suspect-observation');
    },

    suspectReportAlert(myState) {
      return getForecastAlertBySlug(myState, 'suspect-report');
    },

    temperatureForecastAlert(myState) {
      return getForecastAlertBySlug(myState, 'temp');
    },

    temperatureForecastAlertIcon() {
      return this.temperatureForecastAlert?.icon_url;
    },

    trailConditionsForecastAlert(myState) {
      return getForecastAlertBySlug(myState, 'trail-conditions');
    },

    windGustForecastAlert(myState) {
      return getForecastAlertBySlug(myState, 'wind-gust');
    },

    windForecastAlert(myState) {
      return getForecastAlertBySlug(myState, 'wind');
    },

    windForecastAlertIcon() {
      return this.windForecastAlert?.icon_url;
    },
  },
});
