export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"},{"hid":"robots","name":"robots","content":"index,follow"},{"name":"author","content":"OpenSnow"},{"property":"og:type","content":"website"},{"property":"og:site_name","content":"OpenSnow"},{"property":"fb:app_id","content":"242272149152317"},{"name":"twitter:card","content":"summary_large_image"},{"name":"twitter:site","content":"@findOpenSnow"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon/favicon-16x16.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/favicon/apple-touch-icon.png"},{"rel":"manifest","href":"/favicon/site.webmanifest"}],"style":[],"script":[{"children":"\n(function setTheme() {\n  const setDataTheme = (theme = 'light') => document.documentElement.setAttribute('data-theme', theme);\n\n  const url = new URL(window.location.href);\n  const inAppView = url.searchParams.get('in_app_view');\n\n  if (inAppView === 'true') {\n    const mode = url.searchParams.get('mode') || 'light';\n    setDataTheme(mode);\n    return;\n  }\n\n  let theme;\n\n  try {\n    theme = window.localStorage.getItem('theme');\n  } catch (e) {\n    // Do nothing if unable to read from localStorage\n  }\n\n  if (!theme\n      && window\n      && window.matchMedia\n      && window.matchMedia('(prefers-color-scheme: dark)').matches) {\n    theme = 'dark';\n  }\n\n  if (theme) {\n    setDataTheme(theme);\n  }\n}());\n          "}],"noscript":[],"title":"OpenSnow: Snow Forecast | Ski Report & Conditions","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000