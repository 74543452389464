import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "35",
  height: "32",
  viewBox: "0 0 35 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M19.565 22.492v-9.844l-6.521 3.261v9.844l6.521-3.261zm-8.696 3.644V15.951l-6.521-3.558v11.37l6.521 2.372zm10.17-18.574c.338 0 .676.051 1.009.131.11.454.25.902.437 1.332l1.663 3.843-2.408-.742v10.467l8.696 2.677v-3.03l.799-1.845 3.174-7.338c.238.54.376 1.128.376 1.746V25.27c0 1.38-.655 2.677-1.764 3.498a4.354 4.354 0 01-3.861.658l-8.505-2.617-7.022 3.512a4.353 4.353 0 01-3.431.197l-7.337-2.669a4.349 4.349 0 01-2.862-4.086v-11.37a4.348 4.348 0 016.429-3.817l5.526 3.015 7.139-3.572a4.359 4.359 0 011.944-.458zM29.853 0c1.187 0 2.31.505 3.186 1.375.296.294.561.63.793 1.005.063.102.131.199.189.308.198.37.341.767.459 1.172.216.74.292 1.519.239 2.292a6.213 6.213 0 01-.486 2.047l-4.917 11.37-4.917-11.37a6.21 6.21 0 01-.497-2.216 6.576 6.576 0 01.285-2.209c.115-.372.242-.742.426-1.084.074-.141.16-.267.244-.398.237-.367.508-.696.808-.981C26.53.483 27.624.003 28.78.003zm-.533 3.217a2.222 2.222 0 100 4.444 2.222 2.222 0 000-4.444z" }, null, -1)
  ])))
}
export default { render: render }